import React from "react"
import { Link } from "gatsby"
import { Nav, Navbar, Container } from "react-bootstrap"

import Logo from "../../images/logos/holzkette_logo.svg"

//Dark Mode = <Navbar expand="md" bg="dark" variant="dark">

const Header = () => {
  // Query
  // JS
  // JSX
  return (
    <Container>
      <Navbar expand="md" variant="dark">
        <Navbar.Brand>
          <Link to="/">
            <img
              src={Logo}
              width="150"
              height="50"
              className="d-inline-block logoImage"
              alt="Holzkette Logo"
              opcaity
            />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar-collapse-id" />
        <Navbar.Collapse
          className="justify-content-end"
          id="navbar-collapse-id"
        >
          <Nav>
            <Link to="/" className="nav-link">
              Home
            </Link>
            <Link to="/konzept" className="nav-link">
              Konzept
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Container>
  )
}

export default Header
